.swatch {
  box-sizing: border-box;
  composes: root from '@components/ui/Button/Button.module.css';
  @apply h-10 w-10 bg-primary text-primary rounded-full mr-3 inline-flex
  items-center justify-center cursor-pointer transition duration-150 ease-in-out
  p-0 shadow-none border-accent-3 border box-border select-none;
  margin-right: calc(0.75rem - 1px);
  overflow: hidden;
  width: 48px;
  height: 48px;
}

.swatch::before,
.swatch::after {
  box-sizing: border-box;
}

.swatch:hover {
  @apply transform scale-110 bg-hover;
}

.swatch > span {
  @apply absolute;
}

.color {
  @apply text-black transition duration-150 ease-in-out;
}

.color :hover {
  @apply text-black;
}

.color.dark,
.color.dark:hover {
  color: white !important;
}

.active {
  @apply border-accent-9 border-2;
  padding-right: 1px;
  padding-left: 1px;
}

.textLabel {
  @apply w-auto px-4;
  min-width: 3rem;
}

.active.textLabel {
  @apply border-accent-9 border-2;
  padding-right: calc(1rem - 1px);
  padding-left: calc(1rem - 1px);
}
