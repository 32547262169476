.WishlistButton_root__crSyc {
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-property: color, background-color, opacity;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: var(--colors-accent0);
  padding: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: var(--colors-accent9);
}

.WishlistButton_root__crSyc:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.WishlistButton_icon__J690_ {
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-property: transform, fill;
  color: currentColor;
}

.WishlistButton_icon__J690_.WishlistButton_loading__BcgF9 {
  fill: var(--colors-vibrant-pink-light);
}

.WishlistButton_icon__J690_.WishlistButton_inWishlist__uwrZI {
  fill: var(--colors-vibrant-pink);
}

@media (min-width: 1024px) {
  .WishlistButton_root__crSyc {
    padding: 1rem;
  }
}

.ProductTag_root__Nxivt {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
  padding-right: 4rem;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ProductTag_root__Nxivt .ProductTag_name__C_niq {
  width: 100%;
  max-width: 100%;
  padding-top: 0px;
  line-height: var(--lineHeights-extra-loose);
  display: flex;
  flex-direction: column;
  letter-spacing: .4px;
  line-height: 2.1em;
}

.ProductTag_root__Nxivt .ProductTag_vendor__A9vpe {
  white-space: nowrap;
}

.ProductTag_root__Nxivt .ProductTag_name__C_niq span {
  background-color: var(--colors-primary);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 700;
  color: var(--colors-text-primary);
  min-height: 70px;
  font-size: inherit;
  letter-spacing: inherit;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.ProductTag_root__Nxivt .ProductTag_name__C_niq span.ProductTag_fontsizing__XnIPn {
  display: flex;
  padding-top: 1.5rem;
}

.ProductTag_root__Nxivt .ProductTag_price__RDK06 {
  display: inline-block;
  background-color: var(--colors-primary);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: var(--colors-accent9);
  margin-top: -1px;
}

.ProductCard_root__HqXTt {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  max-height: 100%;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  background-color: var(--colors-accent1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-timing-function: linear;
  height: 100% !important;
}

.ProductCard_root__HqXTt:hover .ProductCard_productImage__nbfNy {
    transform: scale(1.2625);
  }

.ProductCard_root__HqXTt:hover .ProductCard_header__qlwPO .ProductCard_name__YciuQ span, .ProductCard_root__HqXTt:hover .ProductCard_header__qlwPO .ProductCard_price___JB_V, .ProductCard_root__HqXTt:hover .ProductCard_wishlistButton__jS6Lf {
  background-color: var(--colors-secondary);
  color: var(--colors-text-secondary);
}

.ProductCard_root__HqXTt:hover:nth-child(6n + 1) .ProductCard_header__qlwPO .ProductCard_name__YciuQ span, .ProductCard_root__HqXTt:hover:nth-child(6n + 1) .ProductCard_header__qlwPO .ProductCard_price___JB_V, .ProductCard_root__HqXTt:hover:nth-child(6n + 1) .ProductCard_wishlistButton__jS6Lf {
  background-color: var(--colors-vibrant-violet);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ProductCard_root__HqXTt:hover:nth-child(6n + 5) .ProductCard_header__qlwPO .ProductCard_name__YciuQ span, .ProductCard_root__HqXTt:hover:nth-child(6n + 5) .ProductCard_header__qlwPO .ProductCard_price___JB_V, .ProductCard_root__HqXTt:hover:nth-child(6n + 5) .ProductCard_wishlistButton__jS6Lf {
  background-color: var(--colors-vibrant-blue);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ProductCard_root__HqXTt:hover:nth-child(6n + 3) .ProductCard_header__qlwPO .ProductCard_name__YciuQ span, .ProductCard_root__HqXTt:hover:nth-child(6n + 3) .ProductCard_header__qlwPO .ProductCard_price___JB_V, .ProductCard_root__HqXTt:hover:nth-child(6n + 3) .ProductCard_wishlistButton__jS6Lf {
  background-color: var(--colors-vibrant-pink);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ProductCard_root__HqXTt:hover:nth-child(6n + 6) .ProductCard_header__qlwPO .ProductCard_name__YciuQ span, .ProductCard_root__HqXTt:hover:nth-child(6n + 6) .ProductCard_header__qlwPO .ProductCard_price___JB_V, .ProductCard_root__HqXTt:hover:nth-child(6n + 6) .ProductCard_wishlistButton__jS6Lf {
  background-color: var(--colors-vibrant-cyan);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ProductCard_header__qlwPO {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
  padding-right: 4rem;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ProductCard_header__qlwPO .ProductCard_name__YciuQ {
  width: 100%;
  max-width: 100%;
  padding-top: 0px;
  line-height: var(--lineHeights-extra-loose);
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 2rem;
  letter-spacing: 0.4px;
}

.ProductCard_header__qlwPO .ProductCard_name__YciuQ span {
  background-color: var(--colors-primary);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 700;
  color: var(--colors-text-primary);
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-size: inherit;
  letter-spacing: inherit;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.ProductCard_header__qlwPO .ProductCard_price___JB_V {
  display: inline-block;
  background-color: var(--colors-primary);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: var(--colors-accent9);
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ProductCard_imageContainer__G6HoR {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ProductCard_imageContainer__G6HoR > div {
  min-width: 100%;
}

.ProductCard_imageContainer__G6HoR .ProductCard_productImage__nbfNy {
  --tw-scale-x: 1.20;
  --tw-scale-y: 1.20;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -o-object-fit: cover;
     object-fit: cover;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.ProductCard_root__HqXTt .ProductCard_wishlistButton__jS6Lf {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 30;
}

/* Variant Simple */
.ProductCard_simple__HMkuK .ProductCard_header__qlwPO .ProductCard_name__YciuQ {
  margin-top: -0.25rem;
  padding-top: 0.5rem;
  font-size: 1.125rem;
  line-height: 2.5rem;
}

.ProductCard_simple__HMkuK .ProductCard_header__qlwPO .ProductCard_price___JB_V {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/* Variant Slim */
.ProductCard_slim__nclhL {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--colors-transparent);
}

.ProductCard_slim__nclhL .ProductCard_header__qlwPO {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 20;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ProductCard_slim__nclhL span {
  display: inline-block;
  overflow-wrap: break-word;
  background-color: var(--colors-accent9);
  padding: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: var(--colors-accent0);
}

.ProductCard_root__HqXTt.secondary .ProductCard_header__qlwPO span {
  background-color: var(--colors-accent0);
  color: var(--colors-accent9);
}

.ProductSliderControl_control__xQi5O {
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
  z-index: 30;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-direction: row;
  border-width: 1px;
  border-color: var(--colors-accent0);
  background-color: var(--colors-vibrant-violet);
  color: var(--colors-accent0);
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 48px;
}

.ProductSliderControl_leftControl__S84sO, .ProductSliderControl_rightControl__NaDLK {
  cursor: pointer;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  transition: background-color 0.2s ease;
}

.ProductSliderControl_leftControl__S84sO:hover,
.ProductSliderControl_rightControl__NaDLK:hover {
  background-color: var(--colors-vibrant-violet-dark);
}

.ProductSliderControl_leftControl__S84sO:focus, .ProductSliderControl_rightControl__NaDLK:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.ProductSliderControl_rightControl__NaDLK {
  border-left-width: 1px;
  border-color: var(--colors-accent0);
}

.ProductSliderControl_leftControl__S84sO {
  margin-right: -1px;
}

.ProductSlider_root__RFy30 {
  --album-height: 100px;
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
}

.ProductSlider_slider__kaFZL {
  position: relative;
  height: 100%;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  opacity: 0;
}

.ProductSlider_slider__kaFZL.ProductSlider_show__FLJHm {
  opacity: 1;
}

.ProductSlider_thumb__an0vK {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity, filter;
  width: 125px;
  height: var(--album-height);
  //filter: saturate(30%);
  //opacity: 0.7;
}

.ProductSlider_thumb__an0vK > span,
  .ProductSlider_thumb__an0vK > span > span {
    height: var(--album-height) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
.ProductSlider_thumb__an0vK:not(.ProductSlider_selected__cUbLA) {
  filter: saturate(0%);
}


.ProductSlider_thumb__an0vK.ProductSlider_selected__cUbLA {
  filter: saturate(100%);
  opacity: 1;
  background-color: var(--colors-transparent)}

.ProductSlider_thumb__an0vK img {
  height: 100% !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.ProductSlider_album__n4YLZ {
  width: 100%;
  height: var(--album-height);
  background-color: var(--colors-vibrant-violet-dark);
  box-sizing: content-box;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ProductSlider_album__n4YLZ::-webkit-scrollbar {
  display: none;
}

@media (min-width: 768px) {
  .ProductSlider_slider__kaFZL {
    height: calc(100% - var(--album-height));
  }

  .ProductSlider_thumb__an0vK:hover {
    transform: scale(1.02);
  }

  .ProductSlider_album__n4YLZ {
    //height: calc(100% - var(--album-height));
  }

  .ProductSlider_thumb__an0vK {
    //width: calc(100% - var(--album-height));
  }
}

.WishlistCard_root__I4EMF {

    display: grid;

    width: 100%;

    grid-template-columns: repeat(12, minmax(0, 1fr));

    grid-gap: 1.5rem;

    gap: 1.5rem;

    border-bottom-width: 1px;

    border-color: var(--colors-accent2);

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    padding-top: 1.5rem;

    padding-bottom: 1.5rem;

    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;

    transition-duration: 100ms;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

  .WishlistCard_root__I4EMF:nth-child(3n + 1) .WishlistCard_productBg__4Lrqi {

    background-color: var(--colors-vibrant-violet)
}

  .WishlistCard_root__I4EMF:nth-child(3n + 2) .WishlistCard_productBg__4Lrqi {

    background-color: var(--colors-vibrant-pink)
}

  .WishlistCard_root__I4EMF:nth-child(3n + 3) .WishlistCard_productBg__4Lrqi {

    background-color: var(--colors-vibrant-blue)
}

.ProductSidebar_root__sSTU0 {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: flex-start;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  overflow-x: hidden;
  min-height: auto;
}

.ProductSidebar_main__Z2pNf {
  position: relative;
  grid-column: span 1 / span 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  min-height: 500px;
}

.ProductSidebar_header__AVh4S {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
  padding-right: 4rem;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ProductSidebar_header__AVh4S .ProductSidebar_name__CdcSB {
  width: 100%;
  max-width: 100%;
  padding-top: 0px;
  line-height: var(--lineHeights-extra-loose);
  font-size: 2rem;
  letter-spacing: 0.4px;
}

.ProductSidebar_header__AVh4S .ProductSidebar_name__CdcSB span {
  background-color: var(--colors-primary);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 700;
  color: var(--colors-text-primary);
  font-size: inherit;
  letter-spacing: inherit;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.ProductSidebar_header__AVh4S .ProductSidebar_price__n_NbV {
  display: inline-block;
  background-color: var(--colors-primary);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: var(--colors-accent9);
}

.ProductSidebar_sidebar__ztp6Q {
  grid-column: span 1 / span 1;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.ProductSidebar_sidebar__ztp6Q iframe[href*="//www.youtube.com/embed"] {
    display: block;
    width: 100%;
    border: 0;
  }

.ProductSidebar_sliderContainer__Z9XAl {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  background-color: var(--colors-vibrant-violet);
}

.ProductSidebar_imageContainer__V1P0v {
  text-align: center;
}

.ProductSidebar_imageContainer__V1P0v > div, .ProductSidebar_imageContainer__V1P0v > div > div {
  height: 100%;
}

.ProductSidebar_sliderContainer__Z9XAl .ProductSidebar_img__8HoPu {
  height: auto;
  max-height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.ProductSidebar_button__13iVS {
  width: 100%;
}

.ProductSidebar_wishlistButton__tVZFA {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 30;
}

.ProductSidebar_relatedProductsGrid__j1exc {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (min-width: 768px) {

  .ProductSidebar_relatedProductsGrid__j1exc {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .ProductSidebar_relatedProductsGrid__j1exc {
    gap: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .ProductSidebar_root__sSTU0 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .ProductSidebar_main__Z2pNf {
    grid-column: span 8 / span 8;
  }

  .ProductSidebar_main__Z2pNf {
    margin-left: 0px;
    margin-right: 0px;
  }

  .ProductSidebar_sidebar__ztp6Q {
    grid-column: span 4 / span 4;
  }

  .ProductSidebar_sidebar__ztp6Q {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .ProductSidebar_imageContainer__V1P0v {
    max-height: 600px;
  }
}

.ProductView_root__hqkHp {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: flex-start;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  /*overflow-x-hidden*/
  max-width: 100%;
}

.ProductView_main__mYQdy {
  position: relative;
  grid-column: span 1 / span 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  min-height: 500px;
}

.ProductView_sidebar__pNv5P {
  grid-column: span 1 / span 1;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.ProductView_sliderContainer__6XH62 {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  background-color: var(--colors-vibrant-violet);
  height: inherit;
}

.ProductView_imageContainer__NY_2C {
  position: relative;
  height: 100%;
  text-align: center;
}

.ProductView_imageContainer__NY_2C > span {
  height: 100% !important;
  position: relative;
  padding: 1rem !important;
  box-sizing: border-box;
}

.ProductView_sliderContainer__6XH62 .ProductView_img__P_Ydq {
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: auto !important;
  height: auto !important;
  min-width: unset !important;
  min-height: unset !important;
  left: 1rem !important;
  right: 1rem !important;
  top: 1rem !important;
  bottom: 1rem !important;
}

.ProductView_button__ro2sl {
  width: 100%;
}

.ProductView_wishlistButton__pWkKB {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 30;
}

.ProductView_relatedProductsGrid__R9_9e {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (min-width: 768px) {

  .ProductView_relatedProductsGrid__R9_9e {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .ProductView_relatedProductsGrid__R9_9e {
    gap: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .ProductView_root__hqkHp {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .ProductView_main__mYQdy {
    grid-column: span 8 / span 8;
  }

  .ProductView_main__mYQdy {
    margin-left: 0px;
    margin-right: 0px;
  }

  .ProductView_main__mYQdy {
    height: calc(100vh - 74px);
    position: -webkit-sticky;
    position: sticky;
    top: 74px;
  }

  .ProductView_sidebar__pNv5P {
    grid-column: span 4 / span 4;
  }

  .ProductView_sidebar__pNv5P {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .ProductView_imageContainer__NY_2C {
    max-height: 600px;
  }
}

.Swatch_swatch__GDm0T {
  margin-right: 0.75rem;
  box-sizing: border-box;
  display: inline-flex;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border-width: 1px;
  border-color: var(--colors-accent3);
  background-color: var(--colors-primary);
  padding: 0px;
  color: var(--colors-text-primary);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  margin-right: calc(0.75rem - 1px);
  overflow: hidden;
  width: 48px;
  height: 48px;
}

.Swatch_swatch__GDm0T::before,
.Swatch_swatch__GDm0T::after {
  box-sizing: border-box;
}

.Swatch_swatch__GDm0T:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-color: var(--colors-hover);
}

.Swatch_swatch__GDm0T > span {
  position: absolute;
}

.Swatch_color__EE_Bp {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.Swatch_color__EE_Bp :hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.Swatch_color__EE_Bp.Swatch_dark__JfVeH,
.Swatch_color__EE_Bp.Swatch_dark__JfVeH:hover {
  color: white !important;
}

.Swatch_active__UtqPO {
  border-width: 2px;
  border-color: var(--colors-accent9);
  padding-right: 1px;
  padding-left: 1px;
}

.Swatch_textLabel__ra4R0 {
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 3rem;
}

.Swatch_active__UtqPO.Swatch_textLabel__ra4R0 {
  border-width: 2px;
  border-color: var(--colors-accent9);
  padding-right: calc(1rem - 1px);
  padding-left: calc(1rem - 1px);
}

