.root {
  --album-height: 100px;

  @apply relative w-full h-full select-none;
  overflow: hidden;
}

.slider {
  @apply relative h-full transition-opacity duration-150;
  opacity: 0;
}

.slider.show {
  opacity: 1;
}

.thumb {
  @apply overflow-hidden inline-block cursor-pointer h-full box-border;
  @apply duration-150 ease-in-out;
  transition-property: opacity, filter;
  width: 125px;
  height: var(--album-height);
  //filter: saturate(30%);
  //opacity: 0.7;


  > span,
  > span > span {
    height: var(--album-height) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
.thumb:not(.selected) {
  filter: saturate(0%);
}


.thumb.selected {
  filter: saturate(100%);
  opacity: 1;
  @apply bg-transparent

}

.thumb img {
  height: 100% !important;
  object-fit: contain !important;
}

.album {
  width: 100%;
  height: var(--album-height);
  @apply bg-violet-dark box-content;
  box-sizing: content-box;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.album::-webkit-scrollbar {
  display: none;
}

@screen md {
  .slider {
    height: calc(100% - var(--album-height));
  }

  .thumb:hover {
    transform: scale(1.02);
  }

  .album {
    //height: calc(100% - var(--album-height));
  }

  .thumb {
    //width: calc(100% - var(--album-height));
  }
}
