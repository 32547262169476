.root {
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-property: color, background-color, opacity;
  @apply p-3 text-accent-9 flex items-center
  justify-center font-semibold cursor-pointer
  bg-accent-0 text-sm;
}

.root:focus {
  @apply outline-none;
}

.icon {
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-property: transform, fill;
  color: currentColor;
}

.icon.loading {
  fill: var(--colors-vibrant-pink-light);
}

.icon.inWishlist {
  fill: var(--colors-vibrant-pink);
}

@screen lg {
  .root {
    @apply p-4;
  }
}
